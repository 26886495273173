import { BsArchive, BsTrash } from "react-icons/bs";
import { BsFillPinFill } from "react-icons/bs";
import { CgDetailsMore } from "react-icons/cg";
import { HiCloudUpload } from "react-icons/hi";
import { useDispatch } from "react-redux";


function NoteView({note}) {

  const dispatch = useDispatch()

  // close current note
  const closeNote=()=>{
    dispatch({type:"CLOSE-NOTE"})
  }

  return (
    <div className="right-pane-view-layer1">
            <div className="right-pane-top-nav">
              <input type="text" className="note-inview-title" placeholder={note.note.title}/>
              <div className="upload-pin">
                <span className="upload-icon">
                  <HiCloudUpload style={{ fontSize: "1.5rem" }} />
                </span>
                <span className="pin-icon">
                  <BsFillPinFill style={{ fontSize: "1.2rem" }} />
                </span>
              </div>
            </div>
             <div className="view-pane" > {/*contentEditable */}
              {note.note.body}
            </div>

            <div className="right-pane-bottom-nav">
              <div className="bottom-nav-left-options">
                <span>
                  <BsArchive style={{ fontSize: "1.2rem" }} />
                  Archive
                </span>
                <span>
                  <BsTrash style={{ fontSize: "1.2rem" }} />
                  Delete
                </span>
                <span>
                  <CgDetailsMore style={{ fontSize: "1.2rem" }} />
                  Details
                </span>
              </div>

              <span className="close" onClick={closeNote}>Close</span>
            </div>
          </div>
  )
}

export default NoteView