import React from 'react'
import LeftPane from './dashboard/LeftPane'
import RightPane from './dashboard/RightPane'

function DashBoard() {
  return (
     <div className="dashboard">
        <LeftPane/>
        <RightPane/>
      </div>
  )
}

export default DashBoard