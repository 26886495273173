import React from 'react'
import { AiOutlineMenu } from "react-icons/ai";
import { BsPencilFill } from "react-icons/bs";
import { FaUserAlt } from "react-icons/fa";
import { FaSortDown } from "react-icons/fa";
import { FaTrashAlt } from "react-icons/fa";
import { MdOutlineArchive } from "react-icons/md";
import { BsFillPinFill } from "react-icons/bs";
import { FcTodoList } from "react-icons/fc";
import { MdEditNote } from "react-icons/md";
import { useDispatch, useSelector } from 'react-redux';

function LeftPaneNav() {

  const store = useSelector(store=>store)
  const dispatch = useDispatch()

  function showUserProfile(){
    dispatch({type:"DISPLAY_USER_PROFILE_PANE", payload:true})
  }

  return (
   <div className="left-pane-top-nav">
            <div>
              <span className="menu">
                <AiOutlineMenu />

                <div className="menu-drop-down">
                  <p style={{marginTop:"1.5em"}}>
                    <MdEditNote /> Notes
                  </p>
                  <p>
                    <FcTodoList /> To-Dos
                  </p>
                  <p>
                    <MdOutlineArchive /> Archive
                  </p>
                  <p>
                    <BsFillPinFill /> Pinned Notes
                  </p>
                  <p style={{marginBottom:"1.5em"}}>
                    <FaTrashAlt /> Bin
                  </p>
                </div>
              </span>
              <span className="logo">
                Safe Keep
                <BsPencilFill style={{ fontSize: ".8rem" }} />
              </span>
            </div>

            <span className="user">
              <FaUserAlt />
              <FaSortDown style={{ fontSize: "0.8rem" }} />

              <div className="user-drop-down">
                <p onClick={showUserProfile}>My profile</p>
                <p className="logout">Log Out</p>
              </div>
            </span>
          </div>
  )
}

export default LeftPaneNav