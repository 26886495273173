import React from 'react'

function NoMatchRoute() {

    const noMatchRouteStyle = {
        textAlign:"center"
    }


  return (
    <div>
        <h1 style={noMatchRouteStyle}>NO MATCH ROUTE</h1>
    </div>
  )
}

export default NoMatchRoute