import React, { useState } from 'react'
import { BsPencilFill } from "react-icons/bs";
import {CgClose} from "react-icons/cg"
import { useDispatch } from 'react-redux';
import user from "../../images/user-avatar-removebg-preview.png"

function UserProfilePane() {

  const [inputState, setInputState] = useState(true)

  const dispatch = useDispatch()

  function closeUserProfile(){
    dispatch({type:"DISPLAY_USER_PROFILE_PANE", payload:false})
  }

  function enableInput(){
    setInputState(false)
  }

  return (
    <div className="user-profile-pane">
            <div className='profile'>
               <span style={{fontSize:"1.5rem", marginBottom:"-.2em", cursor:"pointer"}} className="cancel-profile-icon" onClick={closeUserProfile}><CgClose/></span> Profile
            </div>

            <div className="profile-photo">
                <img src={user}  alt="User-Avatar"/>
            </div>


            <div className="form-container">
            <form action="" className="user-profile-form">
                <div>
                    <label htmlFor="">Your name</label>
                    <input type="text" placeholder='John Doe' disabled={inputState}/>
                    <span style={{cursor:"pointer"}} onClick={enableInput}><BsPencilFill/></span>
                </div>

                <div>
                    <label htmlFor="">Password</label>
                    <input type="password" disabled={inputState} value='*****' />
                    <span style={{cursor:"pointer"}} onClick={enableInput}><BsPencilFill/></span>
                </div>

                <button className='update-profile-btn' disabled={false}>Update Profile</button>
            </form>
            </div>
            
          </div>
  )
}

export default UserProfilePane