import React from 'react'
import { TbCategory } from "react-icons/tb";
import { FaTrashAlt, FaSortDown  } from "react-icons/fa";
import { MdOutlineArchive } from "react-icons/md";
import { BsFillPinFill } from "react-icons/bs";
import { CgDetailsMore } from "react-icons/cg";
import {SlArrowDown} from "react-icons/sl"
import { useDispatch, useSelector } from 'react-redux';



function NoteOptionsModal() {

  const store = useSelector(store=>store)
  const dispatch = useDispatch()
  
  const closeNoteOptions=()=>{
    dispatch({type:"CLOSE_NOTE_OPTIONS"})
  }

  return (
    
    <div className="options-modal-center" style={{maxHeight: store.displayNoteOptions.state? "400px" : "0px"}}>

                <p className='close-note-options' onClick={closeNoteOptions}><SlArrowDown className='arrow-down'/></p>

                <p className="options-modal-child add-to-coll" style={{marginTop:"2em"}}>
                  <TbCategory /> Add to Collection <FaSortDown />

                  <span className='collections-options'>
                    <label htmlFor="" style={{paddingTop:".5em"}}> <input type="checkbox" name="" value="" /> Col. I </label>

                    <label htmlFor=""> <input type="checkbox" name="" value=""/> Col. II </label>

                    <label htmlFor="" style={{paddingBottom:".5em"}}> <input type="checkbox" name="" value="" /> Col. III </label>
                  </span>
                </p>

                <p className="options-modal-child">
                  <MdOutlineArchive
                    style={{ marginRight: ".5em", marginTop: ".5em" }}
                  />
                  Archive
                </p>

                <p className="options-modal-child">
                  <BsFillPinFill
                    style={{ marginRight: ".5em", marginTop: ".5em" }}
                  />
                  Pin
                </p>

                <p className="options-modal-child">
                  <CgDetailsMore
                    style={{ marginRight: ".5em", marginTop: ".5em" }}
                  />
                  Details
                </p>

                <p className="options-modal-child delete-note-option" style={{marginBottom:"2em"}}>
                  <FaTrashAlt
                    style={{ marginRight: ".5em", marginTop: ".5em" }}
                  />
                  Delete
                </p>

              </div>
              
  )
}

export default NoteOptionsModal