import React, { useState } from 'react'
import {FaUserAlt} from "react-icons/fa"
import { BsFillKeyFill, BsEyeSlashFill, BsEyeFill } from "react-icons/bs";
import axios from 'axios'

function Login() {

  const [login, setLogin] = useState({ userName : "", password : ""})

  const [errorMsg, setErrorMsg] = useState({color:"", visibility:"hidden"})

  const [showPassword, setShowPassword] = useState(false);

  const [text, setText] = useState("Invalid Credentials")


  //handle password visibility
  const handleShowPassword=()=>{
    setShowPassword(!showPassword)
  }

  const handleInput=(e)=>{
    const key = e.target.name
    const value = e.target.value
    setLogin({...login, [key]:value})

    setErrorMsg({...errorMsg, visibility:"hidden"}) //display none error msg while typing.
  }

  const handleSubmit=async(e)=>{
    e.preventDefault();

    const { userName, password } = login;

    if(userName === "" || password===""){
      setText("Fields cannot be empty");
      setErrorMsg({ ...errorMsg, color:"rgb(200,0,0)", visibility: "visible"});
    }

    else{
      const response = await axios.get(`http://localhost:5000/api/notes`);
      console.log(response)
    }
  }



  return (
    <div className="signin-form-container">
      <h4 className='signin-error-messages' style={errorMsg}>{text}</h4>
      <form className="signin-form" onSubmit={handleSubmit} >

        <div className='username-container form-input'>
          <FaUserAlt/><input type="text" name='userName' value={login.userName} placeholder="Username" onChange={handleInput} />
        </div>

        <div className='password-container form-input'>
          <BsFillKeyFill/><input type={showPassword ? "text" : "password"} name='password' value={login.password} placeholder='password' onChange={handleInput}/> { showPassword ?
          <BsEyeSlashFill onClick={handleShowPassword} />:
          <BsEyeFill onClick={handleShowPassword} />
          }
        </div>

        <button type='submit' className='signin-btn' disabled={(login.userName.length > 1 && login.password.length > 4) ? false :  !true}>Sign In</button>
      </form>
    </div>
  );
}

export default Login