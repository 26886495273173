import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

// pages
import DashBoard from "./components/pages/DashBoard";
import Home from "./components/pages/Home";
import Login from "./components/forms/Login";
import Registeration from "./components/forms/Registeration";

// StyleSheet
import "./components/styles/styles.css";

import { createStore } from "redux";
import SafeKeepReducer from "./components/reducers/SafeKeepReducer";
import { Provider } from "react-redux";
import NoMatchRoute from "./components/Misc/NoMatchRoute";

function App() {
  const store = createStore(SafeKeepReducer);

  return (
    <BrowserRouter>
      <div className="app">
        <Provider store={store}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/signup" element={<Registeration/>} />
            <Route path="/signin" element={<Login />} />
            <Route path="/dashboard" element={<DashBoard />} />
            <Route path="*" element={<NoMatchRoute/>}/>
          </Routes>
        </Provider>
      </div>
    </BrowserRouter>
  );
}

export default App;
