import React, {useState} from 'react'
import { FaUserAlt } from "react-icons/fa";
import { BsFillKeyFill, BsEyeSlashFill, BsEyeFill } from "react-icons/bs";
import {MdOutlineAlternateEmail} from "react-icons/md"
import axios from 'axios';

function Registeration() {

  // variables
  const [register, setRegister] = useState({ userName : "", email:"", password : ""})

  const [errorMsg, setErrorMsg] = useState({color:"", visibility:"hidden"})

  const [showPassword, setShowPassword] = useState(false)

  const [text, setText] = useState("Invalid Credentials")

  //handle password visibility
  const handleShowPassword=()=>{
    setShowPassword(!showPassword)
  }


  // handle inputs
  const handleInput = (e) => {
    const key = e.target.name;
    const value = e.target.value;
    setRegister({ ...register, [key]: value });

    setErrorMsg({ ...errorMsg, visibility: "hidden" }); //display none error msg while typing.
  };

  // handle submit
  const handleSubmit = async (e) => {
    e.preventDefault();

    const {userName, email, password} = register

     if(userName === "" || email==="" || password===""){
      setText("Fields cannot be empty");
      setErrorMsg({ ...errorMsg, color:"rgb(200,0,0)", visibility: "visible"});
    }

    else{
      const response = await axios.post("http://localhost:5000/api/users/register/",register)
      console.log(response)

      if(response.data.msg === "Username already taken"){
        setText("Username already taken.");
        setErrorMsg({ ...errorMsg, color:"rgb(200,0,0)", visibility: "visible"});
      }

      else if(response.data.msg === "Email already taken"){
        setText("Email used by another account.")
        setErrorMsg({ ...errorMsg, color:"rgb(200,0,0)", visibility: "visible"});
      }

      else if(response.data.msg === "Password length should be greater than 4"){
        setText("Password length too short, should be greater than 4")
        setErrorMsg({ ...errorMsg, color:"rgb(200,0,0)", visibility: "visible"});
      }

      else if (response.data.msg === "user created successfully") {
        setText("Acounted Created")
        setErrorMsg({ ...errorMsg, color:"rgb(0,200,0)", visibility: "visible"});
      }
    }
   };

  return (
    <div className="signin-form-container">
      <h4 className="signin-error-messages" style={errorMsg}>
        {text}
      </h4>

      <form className="signin-form" onSubmit={handleSubmit}>
        <div className="username-container form-input">
          <FaUserAlt />
          <input
            type="text"
            name="userName"
            value={register.userName}
            placeholder="Username"
            onChange={handleInput}
          />
        </div>

        <div className="username-container form-input">
          <MdOutlineAlternateEmail />
          <input
            type="email"
            name="email"
            value={register.email}
            placeholder="email"
            onChange={handleInput}
          />
        </div>

        <div className="password-container form-input">
          <BsFillKeyFill />
          <input
            type={showPassword ? "text" : "password"}
            name="password"
            value={register.password}
            placeholder="password"
            onChange={handleInput}
          />{ showPassword ?
          <BsEyeSlashFill onClick={handleShowPassword} />:
          <BsEyeFill onClick={handleShowPassword} />
          }
        </div>

        <button
          type="submit"
          className="signin-btn"
          disabled={
            register.userName.length > 1 && register.password.length > 4
              ? false
              : !true
          }
        >
          Sign In
        </button>
      </form>
    </div>
  );
}

export default Registeration