import React, { useEffect, useState } from 'react'
import { TbCategory } from "react-icons/tb";
import { BsFunnel } from "react-icons/bs";
import { ImSearch } from "react-icons/im";
import { SlOptionsVertical } from "react-icons/sl";
import axios from "axios"
import {useDispatch} from "react-redux"

function NoteList() {
const activeUser = JSON.parse(localStorage.getItem("safekeepuser"))

  const [notesArr, setNoteArr] = useState([])

  const dispatch = useDispatch()

  // get notes
  const getUserNotes=async()=>{
    const response = await axios.get(`http://localhost:5000/api/notes/${activeUser.userId}`)
    setNoteArr(response.data.getUserNote)
  }

  
useEffect(()=>{
  getUserNotes()
}, [])


  // handle note click
  const handleNoteClick=(e,note)=>{
    if(e.target.parentElement.className !== "note-options"){
      dispatch({type:"VIEW-NOTE", payload: note })
    }
  }

  // display note options
  const displayNoteOptions=(e, note)=>{
    dispatch({type:"VIEW_NOTE_OPTIONS", payload: note })
  }

  return (
    <div className='search-and-notelist-parent'>
     {/* LEFT PANE SEARCH & FILTER SECTION */}
          <div className="left-pane-search">
            <span className="search-container">
              <label>
                <ImSearch />
              </label>
              <input placeholder="search Items..." />
            </span>
            <span className="collection">
              <TbCategory style={{ fontSize: "1.2rem" }} />

              <div className="collection-list-sec">
                <p className="drop-down-header">Note Collections</p>
                <p className="child">All</p>
                {/* <p className="child">Col. I</p>
                <p className="child">Col. II</p>
                <p className="child">Col. III</p> */}
                <p className="new-collection child ">+ Create New Collection</p>
              </div>
            </span>
            <span className="collection sort">
              <BsFunnel style={{ fontSize: "1.2rem" }} />

              <div className="collection-list-sec">
                <p className="drop-down-header">Sort List</p>
                <p className="child">Recently added</p>
                <p className="child">Last added</p>
                <p className="child">Most Viewed</p>
              </div>
              
            </span>
          </div>
        
          <div className="left-pane-note-list-primary-container">
            
            {/* Notes */}
            <div className="note-list-container-secondary">

              <button className='add-note-btn'>Create Note</button>

              {
                notesArr.map((note, index)=>{
                  return (
                    <div className="single-note" onClick={(e)=>handleNoteClick(e, note)} key={index}>

                      <p className="note-title">{note.note.title.length < 15 ? note.note.title : note.note.title.substring(0, 15)+"..." }</p>

                      <p className="note-snippet">{note.note.body.length < 30 ? note.note.body : note.note.body.substring(0, 60)+"..." }</p>

                      <p className="updated-at">{note.updatedAt}</p>

                      <p className="note-options" onClick={(e)=>displayNoteOptions(e, note)}>
                        <SlOptionsVertical className='note-options-icon' />
                      </p>
                    </div>
                  )
                })
              }
            </div>
          </div>
          </div>
  )
}

export default NoteList