import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import NoNoteView from './NoNoteView';
import NoteView from './NoteView';

function RightPane() {

    const [display, setDisplay] = useState("")

    const store = useSelector(store=>store)

    //check screen size
    const mediaQuery = window.matchMedia("(max-width: 576px)")

    //do something
    if(mediaQuery.matches){
      const noteInView = store.noteInView

      if(noteInView !== ""){
        setDisplay(true)
      }
    }
    else{

    }
    

  return (
    <div className="right-pane">

        {   store.noteInView === "" ?
          
          <NoNoteView/> :

          <NoteView note = {store.noteInView}/>
  
        }
        
        </div>
  )
}

export default RightPane