const initialData = {
    noteInView : "",
    displayNoteOptions : {note: "", state:false},
    displayUserProfile : false,
    collections : ""
}

function SafeKeepReducer(state = initialData, action) {
  switch (action.type) {
    case "VIEW-NOTE":
      return {
        ...state,
        noteInView: action.payload,
        displayNoteOptions: { note: "", state: false },
      };
    case "CLOSE-NOTE":
      return {
        ...state,
        noteInView: "",
      };

    case "VIEW_NOTE_OPTIONS":
      return {
        ...state,
        displayNoteOptions: { note: action.payload, state: true },
      };

    case "CLOSE_NOTE_OPTIONS":
      return {
        ...state,
        displayNoteOptions: { note: "", state: false },
      };

    case "DISPLAY_USER_PROFILE_PANE":
      return {
        ...state,
        displayUserProfile: action.payload,
      };

      case "ADD-COLLECTIONS":
        return{
          ...state,
          collections: action.payload
        }

    default: {
      return state;
    }
  }
}

export default SafeKeepReducer