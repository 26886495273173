import React from 'react'
import { useSelector } from 'react-redux';
import LeftPaneNav from './LeftPaneNav';
import NoteList from './NoteList';
import NoteOptionsModal from './NoteOptionsModal';
import UserProfilePane from './UserProfilePane';


function LeftPane() {

  const store = useSelector(store=>store)

  return (
    <div className="left-pane">
        
        {/* LEFT PANE TOP NAVBAR */}
        <LeftPaneNav/>
        
        {/* USER PROFILE PANE */}
        {
          store.displayUserProfile && <UserProfilePane/>
        }
        
        
        {/* NOTE LIST */}
        <NoteList/>

        {/* NOTES OPTIONS MODAL  */}
        <NoteOptionsModal/>


        
        <div className="left-pane-bottom-nav">
        <button className="note-btn">Notes</button>
        <div className="divider"></div>
        <button className="todo-btn">Todo</button>
        </div>
    </div>
  )
}

export default LeftPane